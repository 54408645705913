<template>
  <div>
    <login-dialog ref="loginDialogRef" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginDialog from '@/components/dialogs/LoginDialog.vue';

export default {
  components: { LoginDialog },
  name: 'App',

  data: () => ({}),
  computed: {
    ...mapGetters(['user', 'isAuthenticated']),
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$router.push({ name: 'Calendar', params: { UserId: val.id } });
        }
      },
    },
  },
  methods: {
    showLoginDialog(val) {
      if (!this.isAuthenticated) this.$refs.loginDialogRef.showDialog(val);
    },
  },
  mounted() {
    this.showLoginDialog(true);
  },
  created() {},
};
</script>

<style>
</style>